@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Audiowide&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee&family=Montserrat:wght@200;400;500;600;700&display=swap");

@font-face {
  font-family: 'Azonix';
  src: url('./assets/fonts/Azonix.otf') format('opentype');
}

body {
  @apply font-reg;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 1.5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

// homepage

.hero-video-wrapper {
  // display: flex;
  // flex-direction: row;
  // height: 100vh;;
  // max-height: 100vh;;
  // overflow: hidden;
}

.hero-video-container {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.hero-video {
  pointer-events: none;
  position: relative;
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;
  aspect-ratio: 16 / 9;
}

.hero-video iframe {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  aspect-ratio: 16 / 9;
}

/* Extra Small devices (xs) - 0px and above */
@media screen and (min-width: 0px) {
  .hero-video {
    // border: 2px solid red;
    max-height: 100vh;
    iframe {
      min-height: 125vh;
      min-width: 125vw;
      transform: translate(-45%, 0%);
    }
  }
}

/* Small devices (sm) - 350px and above */
@media screen and (min-width: 350px) {
  .hero-video {
    // border: 2px solid blue;
    max-height: 100vh;
    iframe {
      min-height: 125vh;
      min-width: 125vw;
      transform: translate(-35%, -10%);
    }
  }
}

/* Medium devices (md) - 768px and above */
@media screen and (min-width: 768px) {
  .hero-video {
    // border: 2px solid green;
    max-height: 100vh;
    iframe {
      min-height: 125vh;
      min-width: 125vw;
      transform: translate(-20%, -10%);
    }
  }
}

/* Large devices (lg) - 1024px and above */
@media screen and (min-width: 1024px) {
  .hero-video {
    // border: 2px solid yellow;
    max-height: 100vh;
    iframe {
      min-height: 125vh;
      min-width: 125vw;
      transform: translate(0%, -15%);
    }
  }
}

.fr-cc {
  @apply flex flex-row justify-center items-center;
}
.fr-sc {
  @apply flex flex-row justify-start items-center;
}
.fr-cs {
  @apply flex flex-row justify-center items-start;
}
.fr-ss {
  @apply flex flex-row justify-start items-start;
}
.fc-cc {
  @apply flex flex-col justify-center items-center;
}
.fc-sc {
  @apply flex flex-col justify-start items-center;
}
.fc-cs {
  @apply flex flex-col justify-center items-start;
}
.fc-ss {
  @apply flex flex-col justify-start items-start;
}

.page-wrapper {
  overflow: auto;
  height: calc(100vh - 4rem);
  max-height: calc(100vh - 4rem);
}

.h-page {
  height: calc(100vh - 4rem);
  max-height: calc(100vh - 4rem);
  overflow: auto;
}

.page {
  @apply mx-auto xs:w-[96vw] md:w-[92vw] lg:w-[80rem] p-4 overflow-hidden;
  height: 100%;
  max-height: 100%;
}

::-webkit-scrollbar {
  @apply xs:w-[4px] lg:w-[0.5rem];
  foo: bar;
  // width: 4px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(lighten(#202934, 3), 0.3);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(#06dec3, 1);
}

@layer components {
  .inp-container {
    @apply p-[2px] text-white inline-block rounded-md border border-acc0/50;
    transition: 0.2s all;
    .inp-label {
      @apply p-1;
    }
    &:focus-within {
      @apply border-2 border-acc0;
      .inp-label {
        @apply text-acc0;
      }
    }
    input {
      @apply outline-none bg-transparent p-1 font-mono text-teal-200;
      &:focus {
        @apply outline-none text-white;
        border: none;
      }
    }
  }
}

.img-obey-cont {
  box-sizing: border-box;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.img-btn-grayed {
  @apply cursor-pointer rounded-lg transition duration-100 xs:border lg:border-2 border-acc0 text-acc0;
  background-size: cover;
  background-position: center;
  .do-grayscale {
    filter: grayscale(100%);
  }
  &:hover {
    background-color: transparent;
    filter: none;
  }
  &.exit {
    @apply border border-red-400 text-red-400;
    filter: none;
  }
  &.dont-grayscale {
    filter: none;
  }
}

.racetrack-container {
  // height: 500px;
  // min-height: 450px;
  min-width: 100%;
  overflow: hidden;
  // overflow: visible;
  position: relative;
  z-index: 2;
  .racetrack {
    position: absolute;
    transition: all 1s;
    width: 100%;
    // height: 10450px;
    // background-size: auto 10450px;
    // background-image: url(/assets/4c830a5….svg);
    // margin-bottom: -1088.4px;
  }
}

head-text {
  @apply font-digi xs:text-[1rem] md:text-[2rem] p-2 m-2 pl-1 ml-1;
}

.base-text {
  @apply font-mon xs:text-[0.8rem] md:text-[1.2rem] p-1 m-1 font-thin;
  a: b;
}

.t-text {
  @apply font-mon xs:text-[0.8rem] md:text-[1.2rem] p-1 m-1;
  a: b;
}

.mid-head-text {
  @extend .base-text;
  @apply xs:text-[0.9rem] md:text-[1.3rem] font-reg;
  color: white;
}

.pre-base-text {
  @extend .base-text;
  @apply whitespace-pre-wrap;
  display: block;
}

.page-centered-continer {
  @apply mx-auto h-page max-w-[98vw] w-[80rem] py-[2rem];
  display: block;
  box-sizing: border-box;
}

.resp-text--4 {
  @apply xs:text-[0.35rem] lg:text-[0.6rem];
  a: b;
}
.resp-text--3 {
  @apply xs:text-[0.4rem] lg:text-[0.7rem];
  a: b;
}
.resp-text--2 {
  @apply xs:text-[0.45rem] lg:text-[0.8rem];
  a: b;
}
.resp-text--1 {
  @apply xs:text-[0.5rem] lg:text-[0.9rem];
  a: b;
}
.resp-text-0 {
  @apply xs:text-[0.55rem] lg:text-[1rem];
  a: b;
}
.resp-text-1 {
  @apply xs:text-[0.6rem] lg:text-[1.2rem];
  a: b;
}
.resp-text-2 {
  @apply xs:text-[0.7rem] lg:text-[1.5rem];
  a: b;
}
.resp-text-3 {
  @apply xs:text-[0.8rem] lg:text-[2rem];
  a: b;
}
.resp-p-1 {
  @apply xs:p-0.5 lg:p-1;
  a: b;
}
.resp-p-2 {
  @apply xs:p-1 lg:p-2;
  a: b;
}
.resp-p-3 {
  @apply xs:p-1.5 lg:p-3;
  a: b;
}
.resp-p-4 {
  @apply xs:p-2 lg:p-4;
  a: b;
}
.resp-px-1 {
  @apply xs:px-0.5 lg:px-1;
  a: b;
}
.resp-px-2 {
  @apply xs:px-1 lg:px-2;
  a: b;
}
.resp-px-3 {
  @apply xs:px-1.5 lg:px-3;
  a: b;
}
.resp-px-4 {
  @apply xs:px-2 lg:px-4;
  a: b;
}
.resp-py-1 {
  @apply xs:py-0.5 lg:py-1;
  a: b;
}
.resp-py-2 {
  @apply xs:py-1 lg:py-2;
  a: b;
}
.resp-py-3 {
  @apply xs:py-1.5 lg:py-3;
  a: b;
}
.resp-py-4 {
  @apply xs:py-2 lg:py-4;
  a: b;
}
.resp-m-1 {
  @apply xs:m-0.5 lg:m-1;
  a: b;
}
.resp-m-2 {
  @apply xs:m-1 lg:m-2;
  a: b;
}
.resp-m-3 {
  @apply xs:m-1.5 lg:m-3;
  a: b;
}
.resp-m-4 {
  @apply xs:m-2 lg:m-4;
  a: b;
}
.resp-mx-1 {
  @apply xs:mx-0.5 lg:mx-1;
  a: b;
}
.resp-mx-2 {
  @apply xs:mx-1 lg:mx-2;
  a: b;
}
.resp-mx-3 {
  @apply xs:mx-1.5 lg:mx-3;
  a: b;
}
.resp-mx-4 {
  @apply xs:mx-2 lg:mx-4;
  a: b;
}
.resp-my-1 {
  @apply xs:my-0.5 lg:my-1;
  a: b;
}
.resp-my-2 {
  @apply xs:my-1 lg:my-2;
  a: b;
}
.resp-my-3 {
  @apply xs:my-1.5 lg:my-3;
  a: b;
}
.resp-my-4 {
  @apply xs:my-2 lg:my-4;
  a: b;
}
.resp-gap-1 {
  @apply xs:gap-0.5 lg:gap-1;
  a: b;
}
.resp-gap-2 {
  @apply xs:gap-1 lg:gap-2;
  a: b;
}
.resp-gap-3 {
  @apply xs:gap-1.5 lg:gap-3;
  a: b;
}
.resp-gap-4 {
  @apply xs:gap-2 lg:gap-4;
  a: b;
}

tr.tdrow {
  td {
    @apply border-b border-slate-600 resp-px-2 resp-py-1 text-left xs:min-w-[1rem] lg:min-w-[5rem];
    foo: bar;
  }
}

tr.thintdrow {
  td {
    @apply border-b border-slate-600 resp-px-2 resp-py-1 text-left xs:min-w-[1rem] lg:min-w-[2rem];
    foo: bar;
  }
}

tr.thintdrowp4 {
  td {
    @apply border-b border-slate-600 resp-px-4 resp-py-2 text-left xs:min-w-[1rem] lg:min-w-[2rem];
    foo: bar;
  }
}

.html-overlay {
  @apply select-none;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  width: inherit;
  height: inherit;
  z-index: 100;
}

.card-neon {
  background-color: #111; /* Dark background for neon effect */
  backdrop-filter: blur(10px); /* Blurry background */
  position: relative;
  overflow: hidden;
}

.card-neon::before {
  content: '';
  position: absolute;
  top: -50%;
  right: -50%;
  bottom: -50%;
  left: -50%;
  background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094, #0000ff, #00ff00);
  background-size: 300% 300%;
  z-index: 0;
  animation: move 10s ease infinite;
}

@keyframes move {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}



table.thintdrowp4-table-acc0 {
  td {
    @apply border-b border-acc0 resp-px-4 resp-py-1 text-left xs:min-w-[1rem] lg:min-w-[2rem];
    foo: bar;
  }
}
